import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Carousel from "react-material-ui-carousel";
import Boyimg from "./boy.png";
import {
  Heading2,
  Description,
  MarginWrapper,
  DescriptionBody,
} from "../Common";
import Hecculean from "./hecculean-rare.png";
import "./SliderSection.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#000",
  borderRadius: "0",
  boxShadow: "none",
  paddingTop: "0",
}));

export default function SliderSection() {
  var items = [
    {
      number: "1/2",
      name: "David Chea, Age 10",
      position: "Proud Memory Power Jr Alumni",
      description:
        "Created 'Safe zone' App that marks zones which are not safe for children and provides alerts if someone is entering this area",
      CertificateName: "Herculean",
      CertificateType: "Rare",
    },
    {
      number: "2/2",
      name: "Random Name, Age 30",
      position: "Proud Memory Power Jr Alumni",
      description:
        "Created 'Safe zone' App that marks zones which are not safe for children and provides alerts if someone is entering this area",
      CertificateName: "Herculean",
      CertificateType: "Rare",
    },
  ];
  return (
    <MarginWrapper>
      <Box className="slider">
        <Container
          maxWidth="1350px"
          // sx={{ maxWidth: "1350px" }}
          spacing={2}
        >
          <Grid container spacing={2}>
            <Grid className="custom-slider-mobile" item xs={12} md={12}>
              <Carousel
                animation="fade"
                duration={400}
                navButtonsAlwaysVisible
                autoPlay={false}
                className="certificate-carousel"
              >
                {items.map((item, id) => (
                  <Info key={id} elevation={10} item={item}></Info>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </MarginWrapper>
  );
}

function Info(props) {
  return (
    <Container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={12} md={5}>
          <Box className="certificate-wrapper">
            <Box my={2}>
              <Heading2 bold fontSize="32px" variant="h3" color="#000">
                Memory Power Superstars
              </Heading2>
            </Box>
            <Box my={3}>
              <Heading2 fontSize="30px" variant="h5" color="#000">
                {props.item.number}
              </Heading2>
            </Box>
            <Box my={1}>
              <Heading2 bold fontSize="26px" variant="h3" color="#222222">
                {props.item.name}
              </Heading2>
            </Box>
            <Heading2
              variant="h5"
              fontSize="20px"
              fontWeight={500}
              color="#000"
            >
              {props.item.position}
            </Heading2>
            <Box my={2}>
              <DescriptionBody>{props.item.description}</DescriptionBody>
            </Box>
            <Box className="slider-card" mt={3} style={{ display: "flex" }}>
              <Box sx={{ textAlign: "center", marginRight: "20px" }}>
                <img
                  className="slider-img-cus"
                  src={Hecculean}
                  width="90"
                  alt="Memory Power"
                  loading="lazy"
                />
                <Heading2 variant="h5" bold fontSize="14px" color="#000">
                  {props.item.CertificateName}
                </Heading2>
                <DescriptionBody color="#000">
                  {props.item.CertificateType}
                </DescriptionBody>
              </Box>
              <Box sx={{ textAlign: "center", marginRight: "20px" }}>
                <img
                  className="slider-img-cus"
                  src={Hecculean}
                  width="90"
                  alt="Memory Power"
                  loading="lazy"
                />
                <Heading2 variant="h5" bold fontSize="14px" color="#000">
                  {props.item.CertificateName}
                </Heading2>
                <DescriptionBody color="#000">
                  {props.item.CertificateType}
                </DescriptionBody>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ textAlign: "inherit", ml: 2 }}>
            <Box className="slider-images-boy">
              <img
                loading="lazy"
                src={Boyimg}
                className="img-boy"
                alt="Memory Power"
              />
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
}
