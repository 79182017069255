import React from "react";
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  Description,
  DescriptionBody,
  Heading2,
  MarginWrapper,
} from "./../Common";
import onePNG from "../../image/about-company/3.png";
import twoPNG from "../../image/about-company/2.png";
import threePNG from "../../image/about-company/1.png";
import fourPNG from "../../image/about-company/4.png";
import AboutPNG from "../../image/about-company/aboutCompany.png";
import AboutPNG2 from "../../image/about-company/aboutCompany2.png";
import AboutPNG3 from "../../image/about-company/aboutCompany3.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./AboutCompany.css";
import { BookTrailLink } from "../Button/Button";
import { ContainedButtonTwo } from "../Button/Button";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          <Typography variant="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AboutCompany = () => {
  const [value, setValue] = React.useState(0);
  //   const mobile = Mobile();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6} py={3}>
          <MarginWrapper>
            <Box sx={{ position: "relative" }} className="about-tab-wrapper">
              <Box sx={{ mb: 1.5 }} className="about-tabs">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor={""}
                >
                  <Tab
                    label="Overview"
                    {...a11yProps(0)}
                    className="about-tab-btn"
                  />
                  <Tab
                    label="Our Vision"
                    {...a11yProps(1)}
                    className="about-tab-btn"
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Heading2
                  variant="h3"
                  bold
                  color="#000"
                  fontSize="40px"
                  lineHeight="42px"
                >
                  Learn About Our Work and Company
                </Heading2>
                <br />
                <Description description="• “THE MEMORY POWER” is an EdTech startup company that equips students with the skillset and mindset required to be the future of the companies worldwide. We connect a deep understanding of education with the power of the “21st CENTURY LIFE SKILLS” to make your child ready to face this competitive and challenging world. " />
                <br />
                <Description description="• The universe is a subject created in the minds of humans addressed by the facts and theories since centuries. To think about it in detail requires the strength of knowledge and experience which are used to lead an intellectual life. " />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Heading2
                  variant="h3"
                  bold
                  color="#000"
                  fontSize="40px"
                  lineHeight="42px"
                >
                  Our Vision
                </Heading2>
                <br />
                <Description description="To help your child enhance the understanding of life based on the core concept of the most powerful tool in the world - “THE MEMORY POWER”." />
                <br />
                <Description description="“Memory Power” is an art of practicing the life skills that will provide your children with the important tools for the development of brain, such as deep thinking, making new friends, decision making and taking actions in situations where their parents or teachers are not around to help. " />
              </TabPanel>
              <Box mt={3}>
                {/* <BookTrailLink linkto="/booking" widthprops="fit-content" /> */}
                <ContainedButtonTwo py={1.5} px={8}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <CalendarMonthIcon
                      className="calendar-icon"
                      style={{ height: "30px", width: "30px" }}
                    />
                    <DescriptionBody bold color="#fff">
                      Book a FREE Trial
                    </DescriptionBody>
                  </Box>
                </ContainedButtonTwo>
              </Box>
              <img src={onePNG} alt="brain-3" className="brain-one" />
              <img src={twoPNG} alt="brain-2" className="brain-two" />
            </Box>
          </MarginWrapper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="about">
            <img
              src={AboutPNG}
              alt="Childern Img"
              loading="lazy"
              className="about-png"
            />
            <img
              src={AboutPNG2}
              alt="Childern Img"
              loading="lazy"
              className="about-png2"
            />
            <img
              src={AboutPNG3}
              alt="Childern Img"
              loading="lazy"
              className="about-png3"
            />
            <img src={threePNG} alt="brain-1" className="brain-three" />
            <img src={fourPNG} alt="brain-4" className="brain-four" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutCompany;
