import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import OfferCard1 from "../../image/offer/offer-card-1.png";
import OfferCard2 from "../../image/offer/offer-card-2.png";
import OfferCard3 from "../../image/offer/offer-card-3.png";
import OfferCard4 from "../../image/offer/offer-card-4.png";
import OfferCard5 from "../../image/offer/offer-card-5.png";
import OfferCard6 from "../../image/offer/offer-card-6.png";
import BrainOne from "../../image/offer/brain-1.png";
import BrainTwo from "../../image/offer/brain-2.png";
import BrainThree from "../../image/offer/brain-3.png";
import { DescriptionBody, Heading2, MarginWrapper } from "../Common";
import { ContainedButtonTwo } from "../Button/Button";
import "./OfferCard.css";

const services = [
  {
    images: OfferCard1,
    heading: "Online Teaching",
    description:
      "With online learning programs, parents can teach their kids anytime and anywhere.  ",
  },
  {
    images: OfferCard2,
    heading: "Live Classes",
    description:
      "Although live class ideas are already prevalent and established education business.",
  },
  {
    images: OfferCard3,
    heading: "Trained Teachers",
    description:
      "Teaching is a passion and experts want to share their expertise outside of the institutional sphere.",
  },
  {
    images: OfferCard4,
    heading: "Visual Concepts",
    description:
      "Learning by watching videos is better than reading long pages and bulky books. ",
  },
  {
    images: OfferCard5,
    heading: "Gamified Course",
    description: "Gamification immerses the students into the subject matter.",
  },
  {
    images: OfferCard6,
    heading: "Trained Teachers",
    description:
      "Teaching is a passion and experts want to share their expertise outside of the institutional sphere.",
  },
];
const brains = [
  {
    image: BrainOne,
  },
  {
    image: BrainTwo,
  },
  {
    image: BrainOne,
  },
  {
    image: BrainTwo,
  },
  {
    image: BrainOne,
  },

  {
    image: BrainTwo,
  },
  {
    image: BrainOne,
  },
];

export default function Services() {
  return (
    <>
      <Container data-aos="fade-up" fixed={true}>
        <MarginWrapper>
          <Box className="offer-card-custom">
            {services.map((service, i) => {
              return (
                <Box className="offer-card" key={i}>
                  <img
                    loading="lazy"
                    src={service.images}
                    style={{ width: "37%", marginRight: "15px" }}
                    alt=""
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Heading2 variant="h3" bold fontSize="20px !important">
                      {service.heading}
                    </Heading2>
                    <DescriptionBody className="offer-description">
                      {service.description}
                    </DescriptionBody>
                    <ContainedButtonTwo
                      width="100%"
                      p={1}
                      btnText="Book a FREE Trial Class"
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </MarginWrapper>
      </Container>
      <Container maxWidth="false" sx={{ maxWidth: "1500px" }}>
        <Box className="circles">
          {brains.map((brain, i) => {
            return (
              <img src={brain.image} alt={`brain${brain.image}`} key={i} />
            );
          })}
        </Box>
      </Container>
    </>
  );
}
