import React from "react";
import { Box, Typography, Container } from "@mui/material";
import {
  CallBackContainedButton,
  BookTrailLink,
} from "../../components/Button/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./CallBack.css";

export const CallBack = (props) => {
  return (
    <>
      <Box className="section call-back" sx={props.sectioncss}>
        <Container fixed={true}>
          <Box className="call-back-box">
            <Typography
              variant="h3"
              m={0}
              className="call-back-title"
              sx={props.titlecss}
            >
              {props.title}
            </Typography>
            <CallBackContainedButton
              StartIcon={<CalendarMonthIcon />}
              text="Book a Free Trail"
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export const CallToAction = (props) => {
  return (
    <>
      <Box className="section cta-section" sx={props.sectioncss}>
        <Container sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box className="cta-wrapper">
            <Typography variant="h3" className="cta-title" sx={props.titlecss}>
              {props.title}
            </Typography>
            <Typography className="cta-desc" sx={props.desccss}>
              {props.desc}
            </Typography>
            <BookTrailLink linkto="https://memorypowerbackenddashboard.rabs.support/register" />
          </Box>
        </Container>
      </Box>
    </>
  );
};
