import React from "react";
import { Container, Grid, Typography, IconButton, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Logo from "./../../image/logo/logo.png";
import "./Footer.css";
import { MarginWrapper } from "../Common";

const MoreLink = [
  { Link: "Terms & Conditions" },
  { Link: "Privacy Policy" },
  { Link: "Complaint Redressal" },
  { Link: "Fulfillment Policy" },
  { Link: "Help Desk" },
  { Link: "Video Gallery" },
  { Link: "Apply For Teacher Role" },
];
const ImportantLink = [
  { Link: "Teacher Login" },
  { Link: "Student Login" },
  { Link: "Register" },
  { Link: "Careers" },
];
const SocailMediaLink = [
  { Icon: <FacebookIcon sx={{ color: "#fff" }} /> },
  { Icon: <TwitterIcon sx={{ color: "#fff" }} /> },
  { Icon: <YouTubeIcon sx={{ color: "#fff" }} /> },
  { Icon: <LinkedInIcon sx={{ color: "#fff" }} /> },
];

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fixed={true}>
          <MarginWrapper>
            <Box className="footer-container">
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={1}></Grid> */}
                <Grid item xs={12} md={4}>
                  <img
                    loading="lazy"
                    src={Logo}
                    alt="Memory Power Logo"
                    width={200}
                    className="footer-logo-img"
                  />
                  <br />
                  <Typography
                    variant="paragraph"
                    className="footer-description"
                  >
                    Do you want your child to create the world's next big tech
                    platform? WhiteHat Jr's mission is to empower a whole
                    generation to become creators versus consumers of
                    technology. We teach fundamentals of coding--logic,
                    structure, sequence and algorithmic thinking to enable kids
                    to generate creative outcomes like websites, animations and
                    apps.
                  </Typography>
                  <br />
                  <br />
                  {SocailMediaLink.map((row, id) => {
                    return (
                      <IconButton aria-label="delete" key={id}>
                        {row.Icon}
                      </IconButton>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={3} className="footer-link-list">
                  <Typography variant="h3" className="footer-title">
                    Important Link
                  </Typography>
                  {ImportantLink.map((row, id) => {
                    return (
                      <p className="footer-description" key={id}>
                        {row.Link}
                      </p>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3} className="footer-link-list">
                  <Typography variant="h3" className="footer-title">
                    More
                  </Typography>
                  {MoreLink.map((row, id) => {
                    return (
                      <p className="footer-description" key={id}>
                        {row.Link}
                      </p>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={1}></Grid>
              </Grid>
            </Box>
          </MarginWrapper>
        </Container>
      </footer>
      <Box className="copyright-section">
        <Typography variant="h3">
          © {new Date().getFullYear()} The Memory Power. All Rights Reserved.
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
