import React from "react";
import { Box, Grid } from "@mui/material";
import { Description, MarginWrapper } from "./../Common";
import Charts from "./../Charts/Charts";
import { BookTrailLink } from "../Button/Button";
import "./KidsFuture.css";
import { Heading2 } from "../Common";
// const KidsFutureLevel = [
//     { LevelName: "Master" },
//     { LevelName: "Learner" },
//     { LevelName: "Junior" },
//     { LevelName: "Beginner" },
// ];
// const KidsFutureBar = [
//     { BarName: "Age" },
//     { BarName: "6yrs to 8yrs" },
//     { BarName: "9yrs to 11yrs" },
//     { BarName: "12yrs to 14yrs" },
//     { BarName: "15yrs to 18yrs" },
// ];

const KidsFuture = () => {
  return (
    <MarginWrapper>
      <Grid container spacing={6} className="kid-future-main">
        <Grid
          sx={{ display: "flex", alignItems: "center" }}
          item
          xs={12}
          md={6}
        >
          <Charts />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box px={2}>
            <Heading2
              className="custom-header-management"
              bold
              fontSize="54px"
              variant="h3"
              color="#303030"
              lineHeight="52px"
            >
              HOW DO WE PREPARE <br />
              OUR KIDS FOR THE
              <br />
              FUTURE ?
            </Heading2>
            <br />
            <Description description="Here is a better way to build the mindsets of our kids to lead businesses of the future. This course has been carefully designed to enrich students with the skills needed for them to thrive as creative thinkers, great leaders, public speakers and leading life like “Entrepreneurs”. " />
            <br />
            <Description description="Each module of the course contains fun & engaging assignments that help our students learn the subject matter better through practical implementation. This also helps schools and parents assess improvement and see tangible results of the student’s learning. " />
            <br />
            <BookTrailLink linkto="https://memorypowerbackenddashboard.rabs.support/register" />
          </Box>
        </Grid>
      </Grid>
    </MarginWrapper>
  );
};

export default KidsFuture;
