import React, { useState, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";

import OfferCard from "../../../components/OfferCard/OfferCard";
import AboutCompany from "../../../components/AboutCompany/AboutCompany";
import KidsFuture from "../../../components/KidsFuture/KidsFuture";
import ManagementTeam from "../../../components/ManagementTeam/ManagementTeam";
import LearningTopics2 from "../../../components/LearningTopics/LearningTopics2";
import SliderSection from "../../../components/SliderSection/SliderSection";
import Footer from "../../../components/Footer/Footer";

import {
  ContainedButtonTwo,
  MemorySwitch,
} from "../../../components/Button/Button";
import {
  Heading2,
  Description,
  SectionContainer,
  DescriptionBody,
  MarginWrapper,
} from "../../../components/Common";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import "./Home.css";
import { CallBack } from "../../../components/CallBack/CallBack";
import OurCourse1 from "../../../components/OurCourse/OurCourse1";

const ClasssesLevel = [
  { ClassStd: "1" },
  { ClassStd: "2" },
  { ClassStd: "3" },
  { ClassStd: "4" },
  { ClassStd: "5" },
  { ClassStd: "6" },
  { ClassStd: "7" },
  { ClassStd: "8" },
  { ClassStd: "9" },
  { ClassStd: "10" },
  { ClassStd: "11" },
  { ClassStd: "12" },
];

const Home = () => {
  const [ClassStd, setSelected] = useState(null);

  useEffect(function () {
    Aos.init({ duration: 2000 });
  }, []);

  const toggle = (ClassStd) => {
    // console.log(ClassStd);
    setSelected(ClassStd);
  };

  //   let active = "";

  return (
    <>
      {/* =========== Home ========= */}
      <Container maxWidth="xl">
        <Box className="home section">
          <Box className="home-box">
            <Container
              // className="custom-container"
              fixed={true}
              // maxWidth="lg"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Box> */}
                <Grid container spacing={1}>
                  <Grid
                    item
                    mb={2}
                    md={8}
                    data-aos="fade-right"
                    className="home-heading"
                  >
                    <Heading2
                      variant="h4"
                      color=" rgb(52, 71, 103)"
                      bold
                      // lineHeight="52px"
                      fontSize="42px"
                      // fontStyle="italic"
                      // textAlign={tablet && "center"}
                    >
                      Online Education Courses{" "}
                      <Heading2
                        variant="h4"
                        color=" rgb(52, 71, 103)"
                        fontSize="42px"
                        fontWeight={500}
                      >
                        For Everyone
                      </Heading2>
                    </Heading2>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    data-aos="fade-right"
                    // className="classes-card-grid"
                  >
                    <MarginWrapper>
                      <Box className="classes-card">
                        {""}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <Heading2
                            variant="h3"
                            fontSize="22px"
                            bold
                            color="#000"
                          >
                            Book your FREE class
                          </Heading2>
                          <MemorySwitch />
                        </Box>
                        <DescriptionBody fontSize="14px" mt={1}>
                          Select your child's grade/class in school
                        </DescriptionBody>
                        {/* <Box className="classes-grade-table" my={2}> */}
                        <Grid container mt={2} mb={2.5}>
                          {ClasssesLevel.map((row, id) => {
                            return (
                              // <Box className="classes-grade-card" key={id}>
                              <Grid
                                item
                                lg={2}
                                md={3}
                                xs={4}
                                className={
                                  row.ClassStd === ClassStd
                                    ? "classes-grade-card active"
                                    : "classes-grade-card"
                                }
                                key={id}
                                onClick={() => toggle(row.ClassStd)}
                              >
                                <DescriptionBody fontSize="13px">
                                  Class
                                </DescriptionBody>
                                {/* <Description description={row.ClassStd} /> */}
                                <DescriptionBody
                                  fontSize="16px"
                                  fontWeight={500}
                                >
                                  {row.ClassStd}
                                </DescriptionBody>
                              </Grid>
                              // </Box>
                            );
                          })}
                        </Grid>
                        {/* </Box> */}
                        <ContainedButtonTwo
                          // padding="0.45rem 0 !important"
                          width="100% !important"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0.45rem 0 !important",
                            }}
                          >
                            <CalendarMonthIcon
                              className="calendar-icon"
                              style={{ height: "1.5rem", width: "1.5rem" }}
                            />
                            <DescriptionBody
                              bold
                              color="#fff"
                              fontSize="16px"
                              ml={2}
                            >
                              Schedule a FREE Class
                            </DescriptionBody>
                          </Box>
                        </ContainedButtonTwo>
                      </Box>
                    </MarginWrapper>
                  </Grid>
                </Grid>
                {/* </Box> */}
              </Box>
            </Container>
          </Box>
        </Box>
      </Container>
      {/* =========== Overview Start ========= */}
      <SectionContainer
        SectionBody={
          <Container
            data-aos="fade-up"
            // fixed={true}
          >
            <AboutCompany />
          </Container>
        }
      />
      {/* =========== Offer Section Start ========= */}
      {/* <Container maxWidth="xl"> */}
      <SectionContainer
        SectionType="offer-bg"
        SectionTitle="What Services we Offer"
        SectionBody={
          <Container
            data-aos="fade-up"
            maxWidth="false"
            sx={{ maxWidth: "1400px" }}
          >
            <OfferCard />
          </Container>
        }
      />
      {/* </Container> */}
      {/* =========== Kids Future ========= */}
      {/* <SectionContainer */}
      {/* // SectionTitle="How Do We Prepare Our Kids For The Future ?"
        // SectionType="kid-future"
        // SectionBody={ */}
      <Box className="section-padding">
        <Container data-aos="fade-up" fixed={true}>
          <KidsFuture />
        </Container>
      </Box>
      {/* //   }
      // /> */}
      {/* =========== Learning Topics ========= */}

      <Box className="section">
        <Container data-aos="fade-up" maxWidth="xl">
          <LearningTopics2 />
        </Container>
      </Box>

      {/* =========== Memory Power Superstars ========= */}
      {/* <Container maxWidth="xl"> */}
      <SectionContainer
        SectionType="slider-bg"
        // SectionTitle="What Services we Offer"
        SectionBody={
          <Container
            data-aos="fade-up"
            maxWidth="false"
            sx={{ maxWidth: "1400px" }}
          >
            <SliderSection />
          </Container>
        }
      />
      {/* </Container> */}

      {/* =========== Our Courses ========= */}
      {/* <Container maxWidth="xl"> */}
      <SectionContainer
        SectionType="highlight-section"
        SectionTitle="Our Course"
        SectionBody={
          <Container
            data-aos="fade-up"
            maxWidth="false"
            sx={{ maxWidth: "1300px" }}
          >
            <OurCourse1 />
          </Container>
        }
      />
      {/* </Container> */}

      {/* =========== Management Team ========= */}
      <Container className="management-section" maxWidth="false">
        <Container data-aos="fade-up" fixed={true}>
          <ManagementTeam />
        </Container>
      </Container>
      {/* =========== Call Back Btn ========= */}
      {/* <Container maxWidth="xl"> */}
      <CallBack
        sectioncss={{
          backgroundColor: "#6cc5ff",
        }}
        titlecss={{ color: "#fff" }}
        title="Memory Power is the top coding learning platform for young minds"
      />
      {/* </Container> */}
      {/* <Container maxWidth="xl"> */}
      <Footer />
      {/* </Container> */}
    </>
  );
};

export default Home;
