import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Box from "@mui/material/Box";
import "./Charts.css";

const data = [
  {
    name: "6yrs to 8yrs",
    uv: 4000,
    Leads: 2400,
    amt: 2400,
  },
  {
    name: "9yrs to 11yrs",
    uv: 3000,
    Leads: 10098,
    amt: 2210,
  },
  {
    name: "12yrs to 14yrs",
    uv: 2000,
    Leads: 800,
    amt: 2290,
  },
  {
    name: "15yrs to 18yrs",
    uv: 2780,
    Leads: 3908,
    amt: 2000,
  },
];

const getIntroOfPage = (label) => {
  if (label === "Facebook") {
    return "Monthly leads report";
  }
  if (label === "Google") {
    return "Monthly leads report";
  }
  if (label === "Housing") {
    return "Monthly leads report";
  }
  if (label === "MagicBrick") {
    return "Monthly leads report";
  }
  if (label === "99 Acres") {
    return "Monthly leads report";
  }
  if (label === "Olx") {
    return "Monthly leads report";
  }
  return "";
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} leads : ${payload[0].value}`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
        {/* <p className="desc"></p> */}
      </div>
    );
  }
  return null;
};

export default function Charts() {
  return (
    <Box className="cstbox kids-future-bar-chart">
      <h5 className="Kids-future-headline">Leads Analytics</h5>
      <ResponsiveContainer width="95%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Leads" barSize={20} fill="#7b809a" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
