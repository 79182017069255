import React from "react";
import { Box, Grid } from "@mui/material";
import { Heading2, MarginWrapper } from "../Common";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import "./ManagementTeam.css";
import { BookTrailLink, OutlinedButton } from "../Button/Button";

const ManagementTeam = () => {
  return (
    <Box className="management-team">
      <MarginWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="management-feature-title">
            <Heading2
              className="custom-header-management"
              bold
              fontSize="34px"
              variant="h3"
              color="#fff"
            >
              Memory Power <br /> Management <br /> Team
            </Heading2>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box py={3}>
              <Heading2
                sx={{ lineHeight: "30px" }}
                bold
                fontSize="20px"
                variant="h3"
                color="#fff"
              >
                Top 1% teachers selected by an industry-leading 4-step teacher
                assessment process by top 1% of early childhood experts
              </Heading2>
            </Box>
            <Heading2
              sx={{ lineHeight: "30px" }}
              fontSize="16px"
              variant="h"
              color="#fff"
            >
              Memory power follows a rigorous 4-step Selection Process for
              confirming teacher candidates so your children are taught by the
              Top Early Childhood Experts
            </Heading2>
            <Box mt={3} className="btn-wrapper">
              <OutlinedButton
                StartIcon={<PlayCircleIcon />}
                highlightText="Video Live Comment"
              />
              <BookTrailLink linkto="https://memorypowerbackenddashboard.rabs.support/register" />
            </Box>
          </Grid>
        </Grid>
      </MarginWrapper>
    </Box>
  );
};

export default ManagementTeam;
