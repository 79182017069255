import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "./Common.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h3: {
          fontWeight: 900,
          lineHeight: "32px",
        },
        h4: {
          fontWeight: 900,
        },
        h5: { fontWeight: 800 },
        h6: {
          fontWeight: 900,
        },
        body2: {
          fontWeight: 600,
        },
      },
    },
  },
});

export const Description = (props) => {
  return <Typography className="description">{props.description}</Typography>;
};
export const DescriptionBody = ({ children, bold, className, ...props }) => {
  return (
    <>
      {bold ? (
        <ThemeProvider theme={theme}>
          <Typography
            variant="body2"
            sx={{ color: "#6c757d", letterSpacing: "1px", ...props }}
            className={className}
          >
            {children}
          </Typography>
        </ThemeProvider>
      ) : (
        <Typography
          variant="body2"
          sx={{ color: "#6c757d", letterSpacing: "1px", ...props }}
          className={className}
        >
          {children}
        </Typography>
      )}
    </>
  );
};

export const DescriptionTitle = ({ children, ...props }) => {
  return (
    <Typography
      className="description-title"
      sx={{ letterSpacing: "1px", ...props }}
    >
      {children}
    </Typography>
  );
};

export const Title = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h6" sx={{ color: "#023e8a", ...props }}>
        {children}
      </Typography>
    </ThemeProvider>
  );
};

export const Heading2 = ({ children, variant, bold, ...props }) => {
  return (
    <>
      {bold ? (
        <ThemeProvider theme={theme}>
          <Typography
            variant={variant ? variant : "h5"}
            // className="heading"
            sx={{ color: "#023e8a", ...props }}
          >
            {children}
          </Typography>
        </ThemeProvider>
      ) : (
        <Typography
          variant={variant ? variant : "h5"}
          // className="heading"
          sx={{ color: "#023e8a", ...props }}
        >
          {children}
        </Typography>
      )}
    </>
  );
};
export const DashboardHeading = (props) => {
  return (
    <Box sx={{ display: "flex" }} my={2}>
      <CalendarTodayIcon className="testing-icon" />
      <Typography variant="h3" className="testing" sx={props.css}>
        {props.heading}&nbsp;<span>{props.highlightHeading}</span>
      </Typography>
    </Box>
  );
};

export const CustomInputField = (props) => {
  return (
    <input
      type={props.type}
      placeholder={props.placeholder}
      required={props.required}
      className="custom-input-filed"
      onChange={props.onChange}
      name={props.name}
      sx={props.inlinecss}
    />
  );
};
export const CustomFormGroup = (props) => {
  return (
    <>
      <Box className="custom-form-group">
        <Typography className="custom-form-label">{props.label}</Typography>
        <input
          type={props.type}
          placeholder={props.placeholder}
          required={props.required}
          className="custom-input-filed"
          onChange={props.onChange}
          name={props.name}
          accept={props.accept}
          sx={props.inlinecss}
        />
      </Box>
    </>
  );
};
export const CustomSelectForm = (props) => {
  return (
    <>
      <Box className="custom-form-group">
        <Typography className="custom-form-label">{props.label}</Typography>
        <Select
          labelId={props.label}
          id={props.label}
          placeholder={props.placeholder}
          required={props.required}
          onChange={props.onChange}
          sx={{ width: "100%", height: "35px" }}
        >
          {props.names.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};
export const CustomTextArea = (props) => {
  return (
    <>
      <Box className="custom-form-group">
        <Typography className="custom-form-label">{props.label}</Typography>
        <TextareaAutosize
          name={props.label}
          placeholder={props.placeholder}
          minRows={props.minRows}
          style={props.inlinecss}
        ></TextareaAutosize>
      </Box>
    </>
  );
};
export const PhoneNumber = (props) => {
  return (
    <>
      <Box className="custom-form-group">
        <Typography className="custom-form-label">{props.label}</Typography>
        <PhoneInput country={"in"} containerClass="form-control" />
      </Box>
    </>
  );
};
export const SectionContainer = (props) => {
  return (
    <>
      <Box className={"section " + props.SectionType} sx={props.inlinecss}>
        {/* <Box className="section-title" sx={{ mb: 6 }}>
          <Typography className="description">{props.SectionDesc}</Typography>
          <Typography variant="h2">{props.SectionTitle}</Typography>
        </Box> */}
        {props.SectionDesc || props.SectionTitle ? (
          <Box className="section-title" sx={{ mb: 6 }}>
            <Typography className="description">{props.SectionDesc}</Typography>
            <Typography variant="h2">{props.SectionTitle}</Typography>
          </Box>
        ) : (
          ""
        )}
        {/* <Box mt={5}> */}
        {props.SectionBody}
        {/* </Box> */}
      </Box>
    </>
  );
};

export function UtilityCard({ children, className, ...props }) {
  return (
    <Card
      sx={{
        boxShadow: "0px 5px 40px -15px rgba(0,0,0,0.75)",
        display: "flex",
        alignItems: "center",
        height: "100%",
        borderRadius: "10px",
        ...props,
      }}
      p={2}
      className={className}
    >
      {children}
    </Card>
  );
}

export function MarginWrapper({ children }) {
  return <Box className="margin-wrapper">{children}</Box>;
}
