import React from "react";
import { Box, CardMedia, Divider } from "@mui/material";

import { DescriptionBody, DescriptionTitle, Title } from "./../Common";

import "./OurCourse.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card1PNG from "../../image/ourCourse/Mask Group1.png";
import Card2PNG from "../../image/ourCourse/Mask Group2.png";
import Card3PNG from "../../image/ourCourse/Mask Group3.png";
import BrainPNG from "../../image/ourCourse/brain.png";
import { ContainedButtonTwo } from "../Button/Button";
import { UtilityCard } from "./../Common";

const courses = [
  {
    img: Card1PNG,
    heading: "FUNDAMENTAL OF MEMORY",
    span: "8 classes",
    price: "9,599",
  },
  {
    img: Card2PNG,
    heading: "COMPREHENSIVE MEMORY",
    span: "50 classes",
    price: "49,999",
  },
  {
    img: Card3PNG,
    heading: "PANAROMIC MEMORY",
    span: "100 classes",
    price: "1,35,999",
  },
];

const OurCourse1 = () => {
  return (
    <Box className="course-card-custom">
      {courses.map((course, i) => {
        return (
          <Box className={i === 2 ? "last" : ""}>
            <UtilityCard
              borderRadius="25px"
              className="our-course-main"
              key={i}
            >
              <Box>
                <CardMedia
                  component="img"
                  image={course.img}
                  sx={{ backgroundSize: "cover" }}
                  alt="Students"
                  loading="lazy"
                />
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 1.5,
                    p: 3,
                    ml: "15px",
                  }}
                >
                  <UtilityCard
                    borderRadius="20px"
                    backgroundColor="#6cc5ff"
                    p={4}
                    position="absolute"
                    top="-40px"
                    className="our-course-heading"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Title color="#fff" fontSize="24px">
                        {course.heading}
                        <small
                          style={{
                            fontSize: "14px",
                            marginLeft: "12px",
                            marginBottom: "48x",
                            fontWeight: 400,
                          }}
                        >
                          {course.span}
                        </small>
                      </Title>
                    </Box>
                  </UtilityCard>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 6,
                      gap: 0.8,
                      px: 2,
                    }}
                  >
                    <DescriptionTitle fontWeight={900} fontSize="19px">
                      Curriculum Includes
                    </DescriptionTitle>

                    <DescriptionBody fontSize="16px">
                      Sequence, Fundamentals Coding Blocks, Loops
                    </DescriptionBody>

                    <Box
                      sx={{ display: "flex", gap: 1.5, alignItems: "center" }}
                    >
                      <DescriptionTitle color="#f993d2">
                        View Detailed
                      </DescriptionTitle>
                      <img
                        src={BrainPNG}
                        style={{ height: "35px", width: "45px" }}
                        alt=""
                        loading="lazy"
                      />
                    </Box>

                    <Divider />

                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Title color="#000" fontSize="2.2em">
                        ₹ {course.price}
                      </Title>
                    </Box>

                    <ContainedButtonTwo width="100% !important" mt={2} p={1.5}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <CalendarMonthIcon
                          className="calendar-icon"
                          style={{ height: "30px", width: "30px" }}
                        />
                        <DescriptionBody bold color="#fff">
                          Book a FREE Trial
                        </DescriptionBody>
                      </Box>
                    </ContainedButtonTwo>
                  </Box>
                </Box>
              </Box>
            </UtilityCard>
          </Box>
        );
      })}
    </Box>
  );
};

export default OurCourse1;
