import React from "react";
import { Box } from "@mui/material";
import { DescriptionBody, Heading2 } from "../Common";
import LogicPNG from "../../image/icons/Learning/logic(2).png";
import StructurePNG from "../../image/icons/Learning/structure (2).png";
import CreativeThinkingPNG from "../../image/icons/Learning/creativeThinking.png";
import SequencePNG from "../../image/icons/Learning/sequence.png";
import AlgorithmicThinkingPNG from "../../image/icons/Learning/algorithmicThinking.png";
import LearningPNG from "../../image/learningBanner.png";
import "./LearningTopics.css";

const LearningTopicsData = [
  { icon: LogicPNG, title: "Logics" },
  { icon: StructurePNG, title: "Structure" },
  { icon: CreativeThinkingPNG, title: "Creative Thinking" },
  { icon: SequencePNG, title: "Sequencing" },
  { icon: AlgorithmicThinkingPNG, title: "Algorithmic Thinking" },
];

const LearningTopics2 = () => {
  return (
    <>
      <Box
        className="learning-bg-custom"
        sx={{
          my: 2,
          backgroundImage: `url(${LearningPNG})`,
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            left: 0,
            width: "100%",
            gap: 3,
          }}
        >
          <Heading2 variant="h3" bold color="#fff" fontSize="40px" lineHeight="42px">
            Your Child Will Learn
          </Heading2>
          <DescriptionBody color="#fff">
            The Memory power that good questions drive good answers. Whether
            it's a query <br />
            about the world around us or a challenge.
          </DescriptionBody>

          <Box className="custom-learn-topic">
            {LearningTopicsData.map((icon, i) => (
              <Box
                key={i}
                sx={{
                  textAlign: "center",
                }}
              >
                <img src={icon.icon} className="icon" alt={`icon${i}`} />
                <DescriptionBody color="#fff" fontSize="18px">
                  {icon.title}
                </DescriptionBody>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LearningTopics2;
