import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
// import Headerlogo from "./../../image/logo/logo.png";
import HeaderLogo from "../../image/logo/logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PsychologyIcon from "@mui/icons-material/Psychology";
import MemoryIcon from "@mui/icons-material/Memory";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  HeaderContainedbtnGrp,
  DropDownMenu,
  BookTrailLink,
  HeaderOutlinebtnGrp,
} from "./../Button/Button";
import { NavLink } from "react-router-dom";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Outlet } from "react-router-dom";
import "./Header.css";
import { ContainedButtonTwo } from "./../Button/Button";
import { DescriptionBody } from "../Common";

const Header = () => {
  const [SelectedSubMenu, setSelectedSubMenu] = useState(
    "Fundamental of memory"
  );

  const CourseMenuList = [
    {
      submenu: "Fundamentals of Memory",
      icon: <PsychologyIcon />,
      linkto: "/",
    },
    {
      submenu: "Comprehensive Memory",
      icon: <MemoryIcon />,
      linkto: "/",
    },
    {
      submenu: "Panaromic Memory",
      icon: <SdStorageIcon />,
      linkto: "/",
    },
  ];
  const CurriculumMenuList = [
    { submenu: "Beginner" },
    { submenu: "Junior" },
    { submenu: "Learner" },
    { submenu: "Master" },
  ];

  const MenuItemList = [
    {
      ButtonType: "OutlinedButton",
      ButtonText: "Curriculum + Pricing",
      ButtonHighlight: "",
      SubMenuFun: CurriculumMenuList,
    },
    {
      ButtonType: "OutlinedButton",
      ButtonText: "Courses:",
      ButtonHighlight: SelectedSubMenu,
      SubMenuFun: CourseMenuList,
    },
    {
      ButtonType: "HeaderContainedbtnGrp",
      ButtonText: "",
      ButtonHighlight: "",
      SubMenuFun: "",
    },
    {
      ButtonType: "EffectButton",
      ButtonText: "",
      ButtonHighlight: "",
      SubMenuFun: "",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Show, setShow] = useState(true);
  const open = Boolean(anchorEl);
  const handleOpenNavMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setShow(!Show);
  };
  const handleCloseNavMenu = () => {
    setAnchorEl(null);
    setShow(!Show);
  };

  const HandleChangeMenu = (event) => {
    setSelectedSubMenu(event.target.innerHTML);
  };

  const [navBackground, setNavBackground] = useState("appBarTransparent");

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 80;
      if (show) {
        setNavBackground("appBarSolid");
      } else {
        setNavBackground("appBarTransparent");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <Box
        className={
          navBackground === "appBarTransparent"
            ? "header-transparent"
            : "header-solid"
        }
      > */}
      <Container
        maxWidth="false"
        sx={{ maxWidth: "1300px" }}
        // fixed={true}
        // maxWidth="xl"
      >
        <AppBar
          className={
            navBackground === "appBarTransparent"
              ? "header-transparent"
              : "header-solid"
          }
          position="fixed"
          // color="secondary"
        >
          <CssBaseline />
          <Toolbar
            sx={{ width: "100%", maxWidth: "1300px", mx: "auto" }}
            disableGutters
          >
            <Box sx={{ display: "flex" }}>
              <img
                src={HeaderLogo}
                className={
                  navBackground === "appBarTransparent"
                    ? "header-logo"
                    : "header-transit"
                }
                alt="Logo"
              />
            </Box>
            <Box className="mobile-navbar">
              <IconButton
                id="basic-button"
                size="large"
                aria-label="account of current user"
                aria-controls={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                {Show ? (
                  <MenuIcon className="mobile-menu-icon" />
                ) : (
                  <CloseIcon className="mobile-menu-icon" />
                )}
              </IconButton>
              <Menu
                className="mobil-nav-item"
                id="menu-appbar"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseNavMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Box className="mobile-overly">
                  {CourseMenuList.map((row, id) => {
                    return (
                      <NavLink
                        key={id}
                        to={row.linkto}
                        onClick={handleCloseNavMenu}
                        className="mobile-nav-link"
                      >
                        {row.icon}&nbsp; {row.submenu}
                      </NavLink>
                    );
                  })}
                </Box>
              </Menu>
            </Box>
            <Box className="header-nav-items">
              {MenuItemList.map((row, id) => {
                return (
                  <Box key={id}>
                    {row.ButtonType === "OutlinedButton" ? (
                      <DropDownMenu
                        css={{
                          borderRadius: "0px",
                          padding: "5px 0px !important",
                          fontSize: "15px !important",
                        }}
                        EndIcon={
                          <KeyboardArrowDownIcon
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "0",
                              color:
                                row.ButtonText === "Curriculum + Pricing"
                                  ? "orange"
                                  : "",
                            }}
                          />
                        }
                        text={row.ButtonText}
                        highlightText={row.ButtonHighlight}
                        SubMenuList={row.SubMenuFun}
                        onClick={HandleChangeMenu}
                      />
                    ) : row.ButtonType === "HeaderContainedbtnGrp" ? (
                      <HeaderOutlinebtnGrp
                        text="Join Class Now"
                        linkto="https://memorypowerbackenddashboard.rabs.support/register"
                      />
                    ) : row.ButtonType === "EffectButton" ? (
                      //   <BookTrailLink linkto="/booking" />
                      <>
                        <ContainedButtonTwo
                          className="contained-button"
                          fontSize="14px"
                          padding="10px 40px"
                          // padding="10px 0px"
                          borderRadius="0px"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 1,
                            }}
                          >
                            <CalendarMonthIcon
                              className="calendar-icon"
                              style={{
                                height: "27px",
                                width: "27px",
                              }}
                            />
                            <DescriptionBody bold color="#fff" fontSize="13px">
                              Book a FREE Trial
                            </DescriptionBody>
                          </Box>
                        </ContainedButtonTwo>
                      </>
                    ) : null}
                  </Box>
                );
              })}
              <nav className="header-social-icons">
                <a>
                  <span>
                    <TwitterIcon />
                  </span>
                </a>
                <a>
                  <span>
                    <LinkedInIcon />
                  </span>
                </a>
                <a>
                  <span>
                    <YouTubeIcon />
                  </span>
                </a>
              </nav>
            </Box>
          </Toolbar>
          {/* </Container> */}
        </AppBar>
      </Container>
      {/* </Box> */}
      <Outlet />
    </>
  );
};
export default Header;
